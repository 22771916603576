import { memo } from 'react';
import { useDispatch } from 'react-redux';

import { useMarket } from 'src/hooks/useMarket';
import { useSystem } from 'src/hooks/useSystem';
import { useGoogleOneTapLogin } from '@react-oauth/google';

import { loginGoogleRequestAction } from 'client/contexts/Login/actions';

const GoogleOneTapLogin = () => {
  const dispatch = useDispatch();
  const { market, language } = useMarket();
  const { apiHostName } = useSystem();

  useGoogleOneTapLogin({
    use_fedcm_for_prompt: true,
    onSuccess: data => {
      if (!data) {
        return;
      }

      dispatch(
        loginGoogleRequestAction({
          hostname: apiHostName,
          data: { token: data.credential, ...data },
          market,
          language,
        })
      );
    },

  });

  return null;
};

export default memo(GoogleOneTapLogin);
