import { useContext } from "react"
import { SystemContext } from "src/context/system/"

export const useSystem = () => {
    const system = useContext(SystemContext);

    if (!system) {
        throw new Error("Missing SystemContext provider");
    }

    return system;
}